import React, { useState } from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import Container from './Container';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mdkozepo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        alert('Thank you for your message. We will be in touch soon!');
        setFormData({ name: '', email: '', phone: '', message: '' });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      alert('Sorry, there was an error sending your message. Please try again.');
    }
  };

  return (
    <section id="contact" className="py-20 bg-gray-50">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
              Let's Grow Your Business Together
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Ready to dominate your local market? Get in touch with us today.
            </p>

            <div className="space-y-6 mb-8">
              <a href="tel:9192714871" className="flex items-center group">
                <div className="bg-otto-purple/10 p-3 rounded-full mr-4">
                  <Phone className="h-6 w-6 text-otto-purple" />
                </div>
                <div>
                  <p className="font-semibold text-gray-900">Call Us</p>
                  <p className="text-otto-purple">(919) 271-4871</p>
                </div>
              </a>

              <a href="mailto:ken@seoraleigh.net" className="flex items-center group">
                <div className="bg-otto-purple/10 p-3 rounded-full mr-4">
                  <Mail className="h-6 w-6 text-otto-purple" />
                </div>
                <div>
                  <p className="font-semibold text-gray-900">Email Us</p>
                  <p className="text-otto-purple">ken@seoraleigh.net</p>
                </div>
              </a>

              <div className="flex items-center">
                <div className="bg-otto-purple/10 p-3 rounded-full mr-4">
                  <MapPin className="h-6 w-6 text-otto-purple" />
                </div>
                <div>
                  <p className="font-semibold text-gray-900">Location</p>
                  <p className="text-gray-600">Serving Raleigh & The Triangle Area</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Get Your Free SEO Analysis</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  rows={4}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-otto-purple focus:border-otto-purple"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full bg-otto-purple text-white hover:bg-otto-dark transition-colors py-4 rounded-lg font-semibold text-lg"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;