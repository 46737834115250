import React from 'react';
import { Award, Users, Target, Star, Phone } from 'lucide-react';
import Container from './Container';

const About = () => {
  const achievements = [
    {
      icon: Award,
      title: 'Premier SEO Partner',
      description: 'Trusted by leading Raleigh businesses'
    },
    {
      icon: Users,
      title: 'Dedicated Support',
      description: 'Personal attention to every client'
    },
    {
      icon: Target,
      title: 'Results Focused',
      description: 'Data-driven strategies that work'
    },
    {
      icon: Star,
      title: 'Industry Leaders',
      description: 'Setting SEO standards since 2008'
    }
  ];

  return (
    <section id="about" className="py-20 bg-white">
      <Container>
        <div className="max-w-4xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Why Choose SEORaleigh?
          </h2>
          
          <a href="tel:9192714871" className="flex items-center justify-center text-2xl text-otto-purple font-bold mb-8 hover:text-otto-dark">
            <Phone className="h-6 w-6 mr-2" />
            (919) 271-4871
          </a>

          <div className="prose prose-lg max-w-none mb-12">
            <p className="text-xl text-gray-600 mb-6">
              At SEORaleigh, we combine deep local market knowledge with cutting-edge SEO technology 
              to deliver exceptional results. Our partnership with Search Atlas and their revolutionary 
              OTTO platform enables us to provide unmatched SEO services that consistently outperform 
              traditional approaches.
            </p>

            <p className="text-xl text-gray-600 mb-6">
              What sets us apart is our unique blend of local expertise and advanced technology. 
              We understand the Raleigh market intimately, and we leverage this knowledge alongside 
              OTTO's AI-powered tools to create customized strategies that drive real business growth.
            </p>

            <div className="bg-otto-purple/5 p-8 rounded-2xl mb-6">
              <h3 className="text-2xl font-bold text-otto-purple mb-4">
                Local Expertise Meets AI Innovation
              </h3>
              <p className="text-lg text-gray-800">
                Our strategic partnership with Search Atlas and OTTO platform combines our 15+ years 
                of local SEO experience with state-of-the-art artificial intelligence, setting a new 
                standard in digital marketing excellence for Raleigh businesses.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {achievements.map((achievement, index) => (
            <div 
              key={index}
              className="text-center p-6 rounded-2xl border border-gray-200 hover:border-otto-purple transition-colors"
            >
              <div className="bg-otto-purple/10 w-16 h-16 rounded-xl flex items-center justify-center mb-4 mx-auto">
                <achievement.icon className="h-8 w-8 text-otto-purple" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{achievement.title}</h3>
              <p className="text-gray-600">{achievement.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default About;