import React from 'react';
import PricingComponent from '../components/Pricing';

const Pricing = () => {
  return (
    <div className="pt-32">
      <PricingComponent />
    </div>
  );
};

export default Pricing;