import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, MapPin } from 'lucide-react';
import Container from './Container';

const Footer = () => {
  const footerLinks = {
    Services: [
      { id: 'local-seo', label: 'Local SEO', path: '/services/local-seo' },
      { id: 'web-opt', label: 'Website Optimization', path: '/services/website-optimization' },
      { id: 'content', label: 'Content Strategy', path: '/services/content-strategy' },
      { id: 'analytics', label: 'Analytics & Reporting', path: '/services/analytics' }
    ],
    Company: [
      { id: 'about', label: 'About', path: '/about' },
      { id: 'features', label: 'Features', path: '/features' },
      { id: 'pricing', label: 'Pricing', path: '/pricing' },
      { id: 'contact', label: 'Contact', path: '/contact' }
    ],
    Resources: [
      { id: 'blog', label: 'Blog', path: '/blog' },
      { id: 'case-studies', label: 'Case Studies', path: '/case-studies' }
    ]
  };

  return (
    <footer className="bg-otto-dark text-white py-12">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <Link to="/" className="text-2xl font-bold mb-6 block">
              SEORaleigh
            </Link>
            <div className="space-y-4 text-otto-light">
              <a 
                href="tel:9192714871" 
                className="flex items-center hover:text-otto-yellow transition-colors"
              >
                <Phone className="h-5 w-5 mr-2" />
                (919) 271-4871
              </a>
              <a 
                href="mailto:ken@seoraleigh.net"
                className="flex items-center hover:text-otto-yellow transition-colors"
              >
                <Mail className="h-5 w-5 mr-2" />
                ken@seoraleigh.net
              </a>
              <div className="flex items-start">
                <MapPin className="h-5 w-5 mr-2 mt-1" />
                <span>Serving Raleigh and the Triangle Area</span>
              </div>
            </div>
          </div>

          {Object.entries(footerLinks).map(([title, links]) => (
            <div key={title}>
              <h3 className="font-semibold mb-4">{title}</h3>
              <ul className="space-y-2">
                {links.map(link => (
                  <li key={link.id}>
                    <Link
                      to={link.path}
                      className="text-otto-light hover:text-otto-yellow transition-colors"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="border-t border-otto-light/20 pt-8">
          <p className="text-otto-light text-sm text-center">
            © {new Date().getFullYear()} SEORaleigh. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;