import React from 'react';
import { LucideIcon } from 'lucide-react';

interface FeatureProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon: Icon, title, description }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-otto-yellow/20 p-3 rounded-full mb-4">
        <Icon className="h-6 w-6 text-otto-yellow" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">
        {title}
      </h3>
      <p className="text-otto-light">
        {description}
      </p>
    </div>
  );
};

export default Feature;