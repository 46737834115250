import React from 'react';
import { Phone, Star, Users, MapPin } from 'lucide-react';
import Container from './Container';
import Feature from './Feature';

const features = [
  {
    icon: Star,
    title: '15+ Years Experience',
    description: 'Trusted SEO experts since 2008'
  },
  {
    icon: Users,
    title: '100+ Clients',
    description: 'Local businesses served'
  },
  {
    icon: MapPin,
    title: 'Raleigh Focused',
    description: 'Triangle area specialists'
  }
];

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-br from-otto-purple to-otto-dark pt-32 pb-20">
      <Container>
        <div className="text-center">
          <div className="inline-block bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full text-white mb-6">
            Raleigh's Most Trusted SEO Agency
          </div>
          
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
            Dominate Google Rankings in
            <span className="text-otto-yellow"> Raleigh</span>
          </h1>
          
          <p className="text-xl text-otto-light mb-8 max-w-3xl mx-auto">
            Join hundreds of local businesses who trust us to deliver exceptional SEO results. 
            Ranked #1 SEO agency in Raleigh with over 15 years of experience.
          </p>
          
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-16">
            <a 
              href="tel:9192714871"
              className="inline-flex items-center justify-center rounded-full font-semibold transition-all duration-200 bg-otto-yellow text-otto-purple hover:bg-opacity-90 text-lg px-8 py-4"
            >
              <Phone className="h-5 w-5 mr-2" />
              (919) 271-4871
            </a>
            <button 
              onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
              className="inline-flex items-center justify-center rounded-full font-semibold transition-all duration-200 bg-white/10 text-white hover:bg-white/20 text-lg px-8 py-4"
            >
              Get Free SEO Analysis
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            {features.map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;