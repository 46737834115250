import React from 'react';
import FeaturesComponent from '../components/Features';

const Features = () => {
  return (
    <div className="pt-32">
      <FeaturesComponent />
    </div>
  );
};

export default Features;