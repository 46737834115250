import React from 'react';
import { Shield, Zap, Target, Headphones, BarChart2, MapPin } from 'lucide-react';
import Container from './Container';

const features = [
  {
    icon: Shield,
    title: 'Guaranteed Security',
    description: 'Your data is protected by bank-level security you can trust',
    details: [
      'Feel confident with SOC 2 Type II certification',
      'Rest easy with end-to-end encryption',
      'Stay protected with continuous security monitoring',
      'Access your data securely, anytime, anywhere'
    ]
  },
  {
    icon: Zap,
    title: 'Instant Impact',
    description: 'Watch your rankings climb in weeks, not months',
    details: [
      'See real-time ranking improvements',
      'Get immediate insights into your performance',
      'Follow clear, actionable recommendations',
      'Start attracting more customers right away'
    ]
  },
  {
    icon: Target,
    title: 'Smart Targeting',
    description: 'Discover exactly what your local customers are searching for',
    details: [
      'Uncover hidden opportunities your competitors miss',
      'Target the most valuable local keywords',
      'Understand your ideal customer\'s search behavior',
      'Dominate your specific service area'
    ]
  },
  {
    icon: Headphones,
    title: 'Personal Support',
    description: 'Get expert guidance whenever you need it',
    details: [
      'Reach our team 24/7 for immediate help',
      'Transform your strategy with expert consultation',
      'Learn from personalized training sessions',
      'Succeed with step-by-step implementation support'
    ]
  },
  {
    icon: BarChart2,
    title: 'Crystal-Clear Results',
    description: 'See exactly how your SEO efforts impact your bottom line',
    details: [
      'Track your ROI with precision',
      'Measure what matters to your business',
      'Compare your performance against competitors',
      'Identify your most profitable keywords'
    ]
  },
  {
    icon: MapPin,
    title: 'Local Authority',
    description: 'Become the go-to choice in your local market',
    details: [
      'Stand out in Google Maps and local search',
      'Build trust with consistent business listings',
      'Turn customer reviews into powerful assets',
      'Create content that resonates locally'
    ]
  }
];

const Features = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-neutral-light">
      <Container>
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-otto-purple mb-4">
            Experience the OTTO Difference
          </h2>
          <p className="text-xl text-neutral-dark max-w-2xl mx-auto">
            Discover why SEORaleigh and OTTO help businesses to transform their online presence and attract more customers
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow"
            >
              <div className="bg-otto-purple/10 w-12 h-12 rounded-xl flex items-center justify-center mb-6">
                <feature.icon className="h-6 w-6 text-otto-purple" />
              </div>
              
              <h3 className="text-xl font-bold text-otto-purple mb-3">
                {feature.title}
              </h3>
              
              <p className="text-neutral-dark mb-4">
                {feature.description}
              </p>
              
              <ul className="space-y-2">
                {feature.details.map((detail, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="w-1.5 h-1.5 bg-otto-purple rounded-full mt-2 mr-2 flex-shrink-0" />
                    <span className="text-sm text-neutral-dark">{detail}</span>
                  </li>
                ))}
              </ul>
              
              <button className="mt-6 text-otto-purple font-semibold hover:text-otto-dark transition-colors">
                Learn More
              </button>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Features;