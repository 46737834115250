import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Container from './Container';

const menuItems = [
  { path: '/', label: 'Home' },
  { path: '/#about', label: 'About' },
  { path: '/#one-click-seo', label: 'One Click SEO' },
  { path: '/blog', label: 'Blog' },
  { path: '/contact', label: 'Contact' }
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    if (path.startsWith('/#')) {
      e.preventDefault();
      const sectionId = path.substring(2); // Remove /# from the path
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(false);
      } else if (location.pathname !== '/') {
        // If we're not on the homepage, first navigate to home then scroll
        window.location.href = path;
      }
    }
  };

  return (
    <header className="fixed w-full z-50 bg-otto-purple">
      <Container>
        <nav className="flex items-center justify-between h-20">
          <Link to="/" className="text-2xl font-bold text-white">
            SEORaleigh
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={(e) => handleNavClick(e, item.path)}
                className="text-white hover:text-otto-yellow transition-colors"
              >
                {item.label}
              </Link>
            ))}
            <a 
              href="https://searchatlas.com/otto-seo/?fpr=ken22"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-otto-yellow text-otto-purple hover:bg-opacity-90 px-6 py-2 rounded-full font-semibold transition-colors"
            >
              Get Started
            </a>
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 text-white hover:text-otto-yellow"
          >
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>

        {isOpen && (
          <div className="md:hidden py-4 border-t border-otto-light/20">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={(e) => handleNavClick(e, item.path)}
                className="block px-4 py-2 text-white hover:text-otto-yellow transition-colors"
              >
                {item.label}
              </Link>
            ))}
            <div className="px-4 pt-4 border-t border-otto-light/20">
              <a 
                href="https://searchatlas.com/otto-seo/?fpr=ken22"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-otto-yellow text-otto-purple hover:bg-opacity-90 px-6 py-3 rounded-full font-semibold text-center transition-colors"
              >
                Get Started
              </a>
            </div>
          </div>
        )}
      </Container>
    </header>
  );
};

export default Header;