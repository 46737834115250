import React from 'react';
import { Zap, MousePointer, BarChart2, Target, Clock, Shield } from 'lucide-react';
import Container from './Container';

const OneClickSEO = () => {
  const features = [
    {
      icon: MousePointer,
      title: 'Single Click Optimization',
      description: 'Deploy your entire SEO strategy instantly with OTTO\'s revolutionary one-click system'
    },
    {
      icon: Clock,
      title: 'Real-Time Results',
      description: 'Watch your rankings improve in real-time with continuous AI-powered optimization'
    },
    {
      icon: Shield,
      title: 'Google-Safe Strategy',
      description: 'All optimizations follow Google\'s best practices for sustainable results'
    }
  ];

  return (
    <section id="one-click-seo" className="py-20 bg-gradient-to-br from-otto-purple/5 to-white">
      <Container>
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-otto-purple mb-4">
            One-Click SEO with OTTO
          </h2>
          <p className="text-xl text-neutral-dark max-w-2xl mx-auto">
            Experience the power of AI-driven SEO optimization that delivers real results
          </p>
        </div>

        {/* Top Image Section */}
        <div className="mb-16">
          <img 
            src="https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&w=1200&h=500"
            alt="SEO Analytics Dashboard"
            className="w-full rounded-xl shadow-lg object-cover"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <div className="bg-otto-yellow/20 w-12 h-12 rounded-xl flex items-center justify-center mb-6">
                <feature.icon className="h-6 w-6 text-otto-purple" />
              </div>
              <h3 className="text-xl font-bold text-otto-purple mb-3">
                {feature.title}
              </h3>
              <p className="text-neutral-dark">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* GBP Optimization Section */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-16">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="p-8">
              <h3 className="text-2xl font-bold text-otto-purple mb-4">
                Dominate Google Business Profile Rankings
              </h3>
              <p className="text-neutral-dark mb-6">
                Your Google Business Profile is crucial for local search success. OTTO's advanced AI technology 
                transforms your GBP performance, helping you achieve top rankings in local search results. 
                Our powerful optimization tools ensure your business stands out in Google Maps and local pack results.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="w-2 h-2 bg-otto-purple rounded-full mt-2 mr-2" />
                  <span>Optimize your GBP listing for maximum visibility</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 bg-otto-purple rounded-full mt-2 mr-2" />
                  <span>Track and improve your local pack rankings</span>
                </li>
                <li className="flex items-start">
                  <span className="w-2 h-2 bg-otto-purple rounded-full mt-2 mr-2" />
                  <span>Monitor and respond to customer reviews effectively</span>
                </li>
              </ul>
            </div>
            <div className="relative h-full">
              <img 
                src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&h=600"
                alt="Local SEO Dashboard"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>

        {/* GBP Before/After Results */}
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-2xl font-bold text-otto-purple mb-4">
                Real Results with OTTO's GBP Optimization
              </h3>
              <p className="text-neutral-dark mb-6">
                See how OTTO transformed this business's local presence in just 10 weeks:
              </p>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <div className="bg-red-100 text-red-600 p-2 rounded-full mr-3">
                    Before
                  </div>
                  <span>Not ranking in local pack for target keywords</span>
                </li>
                <li className="flex items-center">
                  <div className="bg-green-100 text-green-600 p-2 rounded-full mr-3">
                    After
                  </div>
                  <span>79% of keywords ranking in top 3 positions</span>
                </li>
              </ul>
            </div>
            <div>
              <img 
                src="https://searchatlas.com/wp-content/uploads/2024/10/Electrician-Service-img.webp"
                alt="GBP Optimization Before and After Results"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OneClickSEO;