import React from 'react';
import { Check } from 'lucide-react';
import Container from './Container';

const Pricing = () => {
  const starterFeatures = [
    '1 OTTO SEO Site Activation',
    'OTTO PPC (coming soon)',
    '5 GSC Site Projects',
    '3 GBP Accounts',
    '2 User Seats',
    'Content Genius - AI Blog and Landing Page Generation',
    '1-click Publishing to WP and Shopify',
    'Aggregator Network Local Citations',
    'Press Release Distribution (via SignalGenesys)',
    'Report Builder + AI Storytelling',
    'Site Explorer',
    'Local SEO Heatmaps/Geogrids',
    'Keyword Explorer, Keyword Magic, Rank Tracker, and Keyword Gap Analysis',
    'GSC Unlimited Keyword Rank Tracking',
    'Keyword, Competitor & Backlink Research Tools',
    'Chrome Extension Access',
    'Authority Building',
    'Cloud Stacks',
    'Press Release Generation (via Signal Genesys)',
    'Aggregator Network Citations',
    'Digital PR / AI-driven Outreach',
    'Backlink Exchange (coming soon)'
  ];

  const growthFeatures = [
    '1 OTTO SEO Site Activation',
    'OTTO PPC (coming soon)',
    '15 GSC Site Projects',
    '10 GBP Accounts',
    'All Starter features plus:',
    'Site Auditor with Realtime Site Monitoring',
    'HARO AI & Digital PR',
    'Journalist Outreach',
    'Expanded quotas',
    'Batch Analysis',
    'AI SEO Recommendation Engine'
  ];

  const proFeatures = [
    '3 OTTO SEO Sites Activation',
    'OTTO PPC (coming soon)',
    'Unlimited GSC Site Projects',
    '25 GBP Accounts',
    'All Growth features plus:',
    'Unlimited GSC Keyword Rank Tracking',
    'AI Generation Quota',
    'Unlimited Site Crawls up to 1 million pages',
    'Full Dashboard Whitelabel',
    'Bulk Workflows for AI content creation, Site Explorer, Page Audits, Site Audits, and URL Analysis',
    'Site Analysis Lead Capture Widget',
    'Integrations',
    'Link Building / Sales Outreach'
  ];

  const getStartedUrl = "https://searchatlas.com/otto-seo/?fpr=ken22";

  return (
    <section className="py-20 bg-gradient-to-b from-white to-neutral-light">
      <Container>
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-otto-purple mb-4">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-neutral-dark max-w-2xl mx-auto">
            Choose the plan that's right for your business
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {/* Starter Plan */}
          <div className="relative bg-white rounded-2xl shadow-lg p-8 border-2 border-transparent hover:border-otto-purple transition-all duration-300">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-otto-purple mb-2">Starter</h3>
              <p className="text-neutral-dark mb-4">Perfect for small businesses</p>
              <div className="flex items-center justify-center mb-4">
                <span className="text-3xl font-bold">$</span>
                <span className="text-6xl font-bold mx-1">99</span>
                <span className="text-xl">/mo</span>
              </div>
              <a 
                href={getStartedUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex w-full items-center justify-center rounded-full font-semibold bg-otto-purple text-white hover:bg-otto-dark transition-colors py-3"
              >
                Get Started
              </a>
            </div>
            <div className="space-y-4">
              {starterFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
                  <span className="text-neutral-dark">{feature}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Growth Plan */}
          <div className="relative bg-otto-purple rounded-2xl shadow-xl p-8 border-2 border-otto-yellow transform lg:scale-105">
            <div className="absolute -top-4 left-1/2 -translate-x-1/2">
              <span className="bg-otto-yellow text-otto-purple px-4 py-1 rounded-full text-sm font-medium">
                Most Popular
              </span>
            </div>
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-white mb-2">Growth</h3>
              <p className="text-otto-light mb-4">Best for growing businesses</p>
              <div className="flex items-center justify-center mb-4">
                <span className="text-3xl font-bold text-white">$</span>
                <span className="text-6xl font-bold text-white mx-1">199</span>
                <span className="text-xl text-white">/mo</span>
              </div>
              <a 
                href={getStartedUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex w-full items-center justify-center rounded-full font-semibold bg-otto-yellow text-otto-purple hover:bg-opacity-90 transition-colors py-3"
              >
                Get Started
              </a>
            </div>
            <div className="space-y-4">
              {growthFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <Check className="h-5 w-5 text-otto-yellow mr-3 flex-shrink-0" />
                  <span className="text-white">{feature}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Pro Plan */}
          <div className="relative bg-white rounded-2xl shadow-lg p-8 border-2 border-transparent hover:border-otto-purple transition-all duration-300">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-otto-purple mb-2">Pro</h3>
              <p className="text-neutral-dark mb-4">For established businesses</p>
              <div className="flex items-center justify-center mb-4">
                <span className="text-3xl font-bold">$</span>
                <span className="text-6xl font-bold mx-1">399</span>
                <span className="text-xl">/mo</span>
              </div>
              <a 
                href={getStartedUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex w-full items-center justify-center rounded-full font-semibold bg-otto-purple text-white hover:bg-otto-dark transition-colors py-3"
              >
                Get Started
              </a>
            </div>
            <div className="space-y-4">
              {proFeatures.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
                  <span className="text-neutral-dark">{feature}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-16 text-center">
          <p className="text-neutral-dark mb-6">
            All plans include: 14-day free trial • No credit card required • Cancel anytime
          </p>
          <div className="inline-flex items-center space-x-2 text-otto-purple">
            <span>Need a custom plan?</span>
            <a 
              href="/contact"
              className="inline-flex items-center justify-center rounded-full font-semibold border-2 border-otto-purple text-otto-purple hover:bg-otto-purple hover:text-white transition-colors px-6 py-2"
            >
              Contact Sales
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Pricing;