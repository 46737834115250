import React from 'react';
import Container from '../components/Container';
import { Search, Calendar, ArrowRight } from 'lucide-react';

const Blog = () => {
  const posts = [
    {
      title: "The Power of One-Click SEO with OTTO",
      date: "March 15, 2024",
      category: "Product Updates",
      excerpt: "Discover how OTTO's revolutionary one-click deployment is transforming the SEO landscape for local businesses.",
      image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&h=400",
      readTime: "5 min read"
    },
    {
      title: "Local SEO Success Story: Harrison Fence",
      date: "March 10, 2024",
      category: "Case Studies",
      excerpt: "How we helped Harrison Fence achieve #1 rankings for 32 keywords in Raleigh, resulting in a 40% increase in leads.",
      image: "https://images.unsplash.com/photo-1595683213102-6eda2b2a13b4?auto=format&fit=crop&w=800&h=400",
      readTime: "4 min read"
    },
    {
      title: "Mastering Google Business Profile in 2024",
      date: "March 5, 2024",
      category: "SEO Tips",
      excerpt: "Essential strategies to optimize your GBP listing and dominate local pack rankings in your service area.",
      image: "https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&w=800&h=400",
      readTime: "6 min read"
    }
  ];

  return (
    <div className="pt-32 pb-20 bg-neutral-light">
      <Container>
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-otto-purple mb-4">
            SEO Insights & Updates
          </h1>
          <p className="text-xl text-neutral-dark max-w-2xl mx-auto">
            Expert tips, success stories, and the latest trends in search engine optimization
          </p>
        </div>

        <div className="grid gap-8">
          {posts.map((post, index) => (
            <article key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="grid md:grid-cols-2 gap-8">
                <div className="relative aspect-video md:aspect-auto">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-8 flex flex-col justify-center">
                  <div className="inline-flex items-center text-sm text-otto-purple font-semibold mb-4">
                    <span className="bg-otto-purple/10 px-3 py-1 rounded-full">
                      {post.category}
                    </span>
                  </div>
                  <h2 className="text-2xl font-bold text-otto-dark mb-4">
                    {post.title}
                  </h2>
                  <p className="text-neutral-dark mb-6">
                    {post.excerpt}
                  </p>
                  <div className="flex items-center text-sm text-gray-500 mb-6">
                    <Calendar className="h-4 w-4 mr-2" />
                    {post.date}
                    <span className="mx-2">•</span>
                    {post.readTime}
                  </div>
                  <button className="inline-flex items-center text-otto-purple font-semibold hover:text-otto-dark">
                    Read More
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Blog;